import React from "react"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import Flex from "@components/elements/flex"
import { graphql } from "gatsby"
import Container from "@components/layout/container"
import Breadcrumb from "@components/blog/breadcrumb"
import ReactHtml from "@components/react-html"

const Legal = ({ data, pageContext }) => {
  const page = data.legal
  return (
    <Layout canonical={pageContext.slug} title={page.title}>
      <Container text>
        <Flex
          gap="2rem"
          margin="4rem 0 10rem"
          marginMd="2rem 0 6rem"
          marginSm="1rem 0 4rem"
        >
          <Breadcrumb
            noPadding
            links={[
              {
                text: "Legal",
                url: "/legal",
              },
              {
                text: page.title,
              },
            ]}
          />
          <Flex gap="2rem">
            <ReactHtml html={page.body} />
          </Flex>
        </Flex>
      </Container>
    </Layout>
  )
}

Legal.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default Legal

export const pageQuery = graphql`
  query legalQuery($id: String!) {
    legal(id: { eq: $id }) {
      title
      slug
      body
    }
  }
`
